import React, { memo } from 'react';

export default memo(function MapMarkerIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00001 0C2.99904 0 0.565918 2.43309 0.565918 5.43456C0.565918 8.58203 3.06642 10.5498 4.41895 12.8945C5.71292 15.1377 5.71192 16 6.00001 16C6.28811 16 6.28714 15.1377 7.58107 12.8945C8.9341 10.5498 11.4341 8.58203 11.4341 5.43456C11.4341 2.43309 9.00098 0 6.00001 0ZM6.00001 7.51269C4.85207 7.51269 3.92139 6.58203 3.92139 5.43456C3.92139 4.28613 4.85204 3.35547 6.00001 3.35547C7.14798 3.35547 8.07864 4.28613 8.07864 5.43456C8.07864 6.58203 7.14795 7.51269 6.00001 7.51269Z"
        fill="#4B4B4B"
      />
    </svg>
  );
});
