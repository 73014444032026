import gql from 'graphql-tag';
import Link from 'next/link';
import React, { memo } from 'react';

import Avatar from '@src/components/Avatar';
import MapMarkerIcon from '@src/components/Icon/MapMarkerIcon';
import { HomePageProjectsForHomeQuery } from '@src/generated/graphql';
import { routes } from '@src/routes/routes';

export const ProjectCardProject = gql`
  fragment ProjectCardProject on Project {
    id
    title
    mainImgUrl
    city {
      name
      prefecture {
        name
      }
    }
    owner {
      id
      userProfile {
        nickname
        avatarUrl
      }
    }
  }
`;

interface Props {
  project: HomePageProjectsForHomeQuery['projectsForHome'][number];
}

export default memo(function ProjectCard({ project }: Props) {
  return (
    <Link href={routes.projectDetail(project.id)}>
      <a className="text-gray-700">
        <div className="w-full rounded-t-[2rem] rounded-b-[1rem] shadow">
          <img
            // TODO: プレースホルダーを修正
            src={project.mainImgUrl || 'https://via.placeholder.com/300'}
            alt={project.title}
            className="object-cover w-full max-h-[200px] rounded-t-[2rem]"
          />
          <div className="p-4">
            <h4 className="font-bold text-left line-clamp-2">
              {project.title}
            </h4>
            <dl className="mt-2">
              <div className="grid grid-cols-11 gap-0">
                <dt className="flex justify-center items-center text-sm font-medium text-gray-500">
                  <Avatar
                    avatarUrl={project.owner.userProfile.avatarUrl}
                    nickname={project.owner.userProfile.nickname}
                    className="w-6 h-6"
                  />
                </dt>
                <dd className="flex col-span-10 items-center ml-2 text-sm text-gray-900">
                  <div className="text-gray-600 line-clamp-1">
                    {project.owner.userProfile.nickname}
                  </div>
                </dd>
              </div>
              <div className="grid grid-cols-11 gap-0 mt-2">
                <dt className="flex justify-center items-center text-sm font-medium text-gray-500">
                  <MapMarkerIcon />
                </dt>
                <dd className="flex col-span-10 items-center ml-2 text-sm text-gray-900">
                  <div className="text-gray-600 line-clamp-1">
                    {project.city?.prefecture.name} {project.city?.name}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </a>
    </Link>
  );
});
