import React from 'react';

interface Props {
  avatarUrl?: string | null;
  nickname: string;
  className?: string;
}

export default function Avatar({ avatarUrl, nickname, className }: Props) {
  return avatarUrl ? (
    <img
      className={['object-cover rounded-full', className].join(' ')}
      src={avatarUrl}
      alt={nickname}
    />
  ) : (
    <div
      className={[
        'flex justify-center items-center bg-gray-100 rounded-full font-bold',
        className,
      ].join(' ')}
    >
      {nickname.substr(0, 1)}
    </div>
  );
}
